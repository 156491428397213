import { BoxGeometry, Color, Mesh, MeshStandardMaterial, PlaneGeometry, MeshPhongMaterial } from "three"
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';

export const allBaseObject = []  // 返回所有基础模型

// 创建地面
export const box = new Mesh(
  new BoxGeometry(20, 20, 20),  // 设置立方体的大小
  new MeshStandardMaterial({   // 设置材质
    color: 'rgb(36, 172, 242)',  // 设置材质的颜色
    metalness: 0.5,   // 金属度 （1 最像金属，0 最不想金属）
    roughness: 0   // 粗糙度（0 最光滑，1 最粗糙）
  })
)
box.name = 'box'  // 设置模型 name

// 给模型添加鼠标移入事件
box.addEventListener("mouseenter", () => {
  box.material.color = new Color("#ff3366")  // 修改材质颜色为红色
})
// 给模型添加鼠标移除事件
box.addEventListener("mouseleave", () => {
  box.material.color = new Color("rgb(36, 172, 242)") // 恢复模型的材质
})


allBaseObject.push(box)  // 添加到模型数组

export const plane = new Mesh(
  new PlaneGeometry(500, 500),
  new MeshPhongMaterial({ color: 0xcbcbcb, specular: 0x474747 })
);
plane.rotation.x = - Math.PI / 2;
plane.position.y = - 0.5;


const loader = new PLYLoader();

loader.load('/data/Lucy100k.ply', function (geometry) {

  geometry.computeVertexNormals();

  const material = new MeshStandardMaterial({ color: 0x009cff, flatShading: true });
  const mesh = new Mesh(geometry, material);

  mesh.position.y = - 0.2;
  mesh.position.z = 0.3;
  mesh.rotation.x = - Math.PI / 2;
  mesh.scale.multiplyScalar(0.001);
  allBaseObject.push(mesh)
});