<template>
    <div style="width: 100%; height: 100%;">
        <el-row :gutter="20" style="height: 60px;">
            <el-col :span="6">
                <div class="grid-content bg-purple">
                    <el-tag>货叉类型</el-tag>
                    <el-select v-model="value1" placeholder="请选择" @change="value1changed">
                        <el-option v-for="item in options1" :key="item.序号" :label="item.货叉类型名称" :value="item.货叉类型编号">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple">
                    <el-tag>负载kg</el-tag>
                    <el-select v-model="value2" :disabled="!value1" placeholder="请选择" @change="value2changed">
                        <el-option v-for="item in options2" :key="item.序号" :label="item.负载" :value="item.负载">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple">
                    <el-tag>伸出行程</el-tag>
                    <el-select v-model="value3" :disabled="!value1 || !value2" placeholder="请选择"
                        @change="value3changed">
                        <el-option v-for="item in options3" :key="item.value" :label="item.行程" :value="item.行程">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple">
                    <el-tag>伸缩截面尺寸</el-tag>
                    <el-select v-model="value4" :disabled="!value1 || !value2 || !value3" placeholder="请选择"
                        @change="value4changed">
                        <el-option v-for="item in options4" :key="item.value" :label="item.伸缩截面尺寸" :value="item.伸缩截面尺寸">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-table :data="tabledata" style="width: 100%" max-height="240px" size="mini" highlight-current-row @current-change="handleCurrentChange">
                <el-table-column fixed prop="标准型号" label="标准型号" width="180">
                </el-table-column>
                <el-table-column prop="负载" label="负载kg">
                </el-table-column>
                <el-table-column prop="伸缩截面尺寸" label="截面尺寸">
                </el-table-column>
                <el-table-column prop="行程" label="行程" width="100">
                </el-table-column>
                <el-table-column prop="底板长度" label="底板长度">
                </el-table-column>
                <el-table-column prop="上叉长度" label="上叉长度">
                </el-table-column>
                <el-table-column prop="下叉长度" label="下叉长度">
                </el-table-column>
                <el-table-column prop="叉体宽度" label="叉体宽度">
                </el-table-column>
                <el-table-column prop="叉体高度" label="叉体高度">
                </el-table-column>
                <el-table-column prop="安装高度" label="安装高度">
                </el-table-column>
                <el-table-column prop="安装孔距离" label="安装孔距">
                </el-table-column>
                <el-table-column prop="挠度" label="挠度">
                </el-table-column>
            </el-table>

        </el-row>
        <el-row style="height: 680px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane style="width: 900px; height: 600px;" label="3D预览" name="first">
                    <div class="three-canvas" ref="threeTarget"></div>
                </el-tab-pane>
                <el-tab-pane label="配置参数" name="second">配置参数</el-tab-pane>
                <el-tab-pane label="资料下料" name="third">资料下料</el-tab-pane>
            </el-tabs>

        </el-row>
    </div>



</template>

<script>
import { ThreeEngine } from '../components/js/TEngine'
import { allBaseObject,plane } from '../components/js/TBaseObject'
import { allLights } from '../components/js/TLights'
import { allHelper } from '../components/js/THelper'

export default {
    name: 'HelloWorld',
    data() {
        return {
            ThreeEngine: null,
            options1: [],
            value1: null,
            options2: [],
            value2: null,
            options3: [],
            value3: null,
            options4: [],
            value4: null,
            activeName: 'first',
            forkdata: [],
            tabledata: []
        };
    },
    mounted() {
        this.ThreeEngine = new ThreeEngine(this.$refs.threeTarget)

        this.ThreeEngine.addObject(...allBaseObject)  // 添加基础模型     
        
        this.ThreeEngine.addObject(...allLights)  // 添加光线
        //this.ThreeEngine.addObject(...allHelper)   // 添加辅助

        fetch('/data/forkdata.json')
            .then(response => response.json())
            .then(res => {
                this.forkdata = res;

                this.options1 = this.forkdata.filter((obj, index, self) =>
                    index === self.findIndex((t) => t.货叉类型名称 === obj.货叉类型名称)
                );

                //console.log(this.options)
            })

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        value1changed() {
            this.value2 = null;
            this.value3 = null;
            this.value4 = null;
            this.options2 = [];
            this.options3 = [];
            this.options4 = [];
            this.tabledata = this.forkdata.filter((obj, index, self) =>
                obj.货叉类型编号 == this.value1
            );
            this.options2 = this.forkdata.filter((obj, index, self) =>
                index === self.findIndex((t) => t.货叉类型编号 == this.value1 && t.负载 === obj.负载)
            );
        },
        value2changed() {
            this.value3 = null;
            this.value4 = null;
            this.options3 = [];
            this.options4 = [];
            this.tabledata = this.forkdata.filter((obj, index, self) =>
                obj.货叉类型编号 == this.value1 && obj.负载 === this.value2
            );
            this.options3 = this.forkdata.filter((obj, index, self) =>
                index === self.findIndex((t) => t.货叉类型编号 == this.value1 && t.负载 === this.value2 && t.行程 === obj.行程)
            );
        },
        value3changed() {
            this.value4 = null;
            this.options4 = [];
            this.tabledata = this.forkdata.filter((obj, index, self) =>
                obj.货叉类型编号 == this.value1 && obj.负载 === this.value2 && obj.行程 === this.value3
            );
            this.options4 = this.forkdata.filter((obj, index, self) =>
                index === self.findIndex((t) => t.货叉类型编号 == this.value1 && t.负载 === this.value2 && t.行程 === this.value3 && t.伸缩截面尺寸 === obj.伸缩截面尺寸)
            );
        },
        value4changed() {
            this.tabledata = this.forkdata.filter((obj, index, self) =>
                obj.货叉类型编号 == this.value1 && obj.负载 === this.value2 && obj.行程 === this.value3 && obj.伸缩截面尺寸 === this.value4
            );
        },
        handleCurrentChange(val) {
           this.ThreeEngine.removeObject(...allBaseObject);
           this.ThreeEngine.resetCamera();
           this.ThreeEngine.addObject(...allBaseObject)
      }
    }
}
</script>

<style scoped>
.three-canvas {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #d6eaff;
}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #eceef0;
}

.bg-purple {
    background: #f8f9fa;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>