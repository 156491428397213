<template>
  <div class="main">
    <div class="container">
      <div class="infoContainer">
        <div>
          <p class="title">深圳市欣畅达科技有限公司</p>
          <p class="info">深圳总部：深圳市宝安区石岩街道塘头社区塘头一号路8号创维创新谷6楼607 </p>
          <p class="info">惠州工厂：惠州市博罗县龙溪镇结窝村广惠加油站对面 </p>
          <p class="info">手机：18129507720 </p>
          <p class="info">电话：0752-6116567 </p>
          <p class="info">邮箱：contact@szxcd.net </p>
        </div>
        <img src="http://www.szxcd.com.cn/content/img/cw.jpeg" alt="">
        <img src="http://www.szxcd.com.cn/content/img/gc.jpeg" alt="">
      </div>
      <div class="mapcontainer">
        <baidu-map class="bm-view" :center="center" :zoom="zoom" @ready="handler">
          <bm-info-window :position="{lng: 113.928623,lat: 22.646009}" title="深圳总部" @close="infoWindowClose" :show="infoWindow.show"  @open="infoWindowOpen">
            <p v-text="infoWindow.contents"></p>
          </bm-info-window>
          <bm-marker :position="{lng: 113.928623,lat: 22.646009}" :dragging="true" @click="infoWindowOpen"></bm-marker>
        </baidu-map>
        <baidu-map class="bm-view" :center="center2" :zoom="zoom2" @ready="handler2">
          <bm-info-window :position="{lng: 114.143827, lat: 23.160894,}" title="惠州工厂" @close="infoWindowClose2" :show="infoWindow2.show"  @open="infoWindowOpen2">
            <p v-text="infoWindow2.contents"></p>
          </bm-info-window>
          <bm-marker :position="{lng: 114.143827, lat: 23.160894}" :dragging="true" @click="infoWindowOpen2"></bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'contact',
  components: {
  },
  data () {
    return {
      center: {lng: 0, lat: 0},
      center2: {lng: 0, lat: 0},
      zoom: 3,
      zoom2: 3,
      infoWindow: {
        show: true,
        contents: '地址：深圳市宝安区石岩街道塘头社区塘头一号路8号创维创新谷6楼607'
      },
      infoWindow2: {
        show: true,
        contents: '地址：惠州市博罗县龙溪镇结窝村广惠加油站对面'
      }
    }
  },
  methods: {
    handler ({BMap, map}) {
      this.center.lng = 113.928623,
      this.center.lat = 22.646009
      this.zoom = 15
    },
    handler2 ({BMap, map}) {
      this.center2.lng = 114.143827,
      this.center2.lat = 23.160894,
      this.zoom2 = 15
    },
    infoWindowClose (e) {
      this.infoWindow.show = false
    },
    infoWindowOpen (e) {
      this.infoWindow.show = true
    },
    infoWindowClose2 (e) {
      this.infoWindow2.show = false
    },
    infoWindowOpen2 (e) {
      this.infoWindow2.show = true
    },
  },
  created () {
    // this.handler()
    window.scrollTo(0,0)
  },
}
</script>

<style scoped>
.mapcontainer{
  display: flex;
  justify-content: space-between;
}
.bm-view {
  width: 49%;
  height: 313px;
  margin: 10px 0;
}
.infoContainer{
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
}
.infoContainer>div,.infoContainer>img{
  width: 390px;
}
.title{
  font-size: 22px;
  font-weight: bold;
  line-height: 44px;
}
.info{
  font-size: 20px;
  line-height: 40px;
}
</style>
